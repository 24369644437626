import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap';

import { customer } from '../conf/customer'
import { partner } from '../conf/partner'
import { certificat } from '../conf/certificat'
import Goldpartnertime from '../images/Goldpartnertime.png'
import Goldpartnerspesen from '../images/Goldpartnerspesen.png'
import Gold from '../downloads/Gold_2025_LEHMANN+PARTNER_Informatik_AG.pdf'
import solutionExpertTime from '../images/abacusSolutionExpertTime.png'


class Customers extends React.Component {

  state = {
    customer: [],
    groups: [],
    certificat: [],
    selectedGroup: null
  }

  componentDidMount = () => {
    let allGroups = [];

    allGroups.push("Alle");
    customer.map((c) => {
      if (allGroups.indexOf(c.group) === -1) {
        allGroups.push(c.group);
      }
      return null;
    })
    this.setState({
      groups: allGroups,
      customer: customer.sort(() => { return 0.5 - Math.random() })
    });
  }

  filterCustomers = (group) => {
    if (group === "Alle") {
      this.setState({ selectedGroup: null });
    }
    else {
      this.setState({ selectedGroup: group });
    }
  }

  renderGroups = () => (
    <div className="button-group">
      {this.state.groups.map((g, i) => {
        return (<a className="group-button" index={"group_" + i} onClick={() => this.filterCustomers(g)}>{g}</a>)
      })}
    </div>
  )

  renderRefrence = (imgName, name, link, img, index) => (
    <Col className="reference" xs="6" sm="4" md="3" lg="2" key={"div_" + index}>
      <div className="reference-container" >
        <a href={link} rel="noopener noreferrer" target="_blank" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140px' }}>
          <img src={img} alt={imgName} width="140"/>
        </a>
        <a href={link} rel="noopener noreferrer" target="_blank" className="name">{name}</a>
      </div>
    </Col>
  )


 /*renderZertifizierungen = (imgName, name, link, img, index) => (
    <Col className="reference" xs="6" sm="4" md="3" lg="2" key={"div_" + index}>
      <div className>
        <a href={link} rel="noopener noreferrer" target="_blank" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '180px' }}>
          <img src={img} alt={imgName} width="120"/>
        </a>
        
      </div>
    </Col>
  )*/s


   
     
    renderZertifizierungen = (imgName, name, link, img, index) => (
      <Col 
        className="reference" 
        xs={12}  // 2 Spalten auf extra kleinen Bildschirmen (Handy)
        sm={12}  // 3 Spalten auf kleinen Bildschirmen (kleine Tablets)
        md={12}  // 4 Spalten auf mittleren Bildschirmen (größere Tablets)
        lg={index === 0 ? 4 : 1}  // Erster Eintrag größer, andere gleichmäßig
        xl={index === 0 ? 4 : 1}  // Gleiche Regel für extra große Bildschirme
        key={"div_" + index}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '180px' }}>
          <a href={link} rel="noopener noreferrer" target="_blank">
            <img src={img} alt={imgName} width={index === 0 ? "340" : "120"} />
          </a>
        </div>
      </Col>
    );
    
    


  /*renderCertificatRefrence = (imgname, link, img, index) => (
    <Col className="referenceCertificat" xs="3" sm="2" md="1" lg="1" key={"div_" + index}>
        <a href={link} target="_blank"><img src={img} alt={imgname} width="345px"/></a>
    </Col>
  )*/


  render() {
    const customerImages = require.context('../images/customers/', true);
    const partnerImages = require.context('../images/partner/', true);
    const certificatImages = require.context('../images/certificat/', true);
    return (
      <Layout>
        <section id="customer" className="section-wrapper">
          <header className="section-header">
            <h3>Kunden</h3>
          </header>

          {this.renderGroups()}

          <Row>
            {
              this.state.customer.map((c, index) => {
                let img = customerImages('./' + c.img);

                if (this.state.selectedGroup != null && this.state.selectedGroup != c.group) {
                  return
                }

                return this.renderRefrence(c.img, c.name, c.link, img, index);
              })
            }
          </Row>
        </section>
        <section id="certificat" className="section-wrapper">
          <header className="section-header">
            <h3>Zertifizierungen</h3>
          </header>
          <Row>
            {
              certificat.map((c, index) => {
                let img = certificatImages('./' + c.img);
                return this.renderZertifizierungen(c.img, c.name, c.link, img, index);
              })
            }
          </Row>

        </section>

        <section id="partner" className="section-wrapper">
          <header className="section-header">
            <h3>Partner</h3>
          </header>
          <Row>
            {
              partner.map((c, index) => {
                let img = partnerImages('./' + c.img);
                return this.renderRefrence(c.img, c.name, c.link, img, index);
              })
            }
          </Row>

        </section>
        {/*<section id="testimonials" className="section-wrapper">
          <header className="section-header">
            <h3>Testimonials</h3>
          </header>
          </section>*/}
      </Layout >

    )
  }
}

export default Customers
