import Gold25 from '../downloads/Gold_2025_LEHMANN+PARTNER_Informatik_AG.pdf'
import Abanet from '../images/certificat/25_Abacus-Zertifizierungssiegel_AbaNet_DE.png'
import Abatime from '../images/certificat/25_Abacus-Zertifizierungssiegel_AbaTime_DE.png'
import Hrb from '../images/certificat/25_Abacus-Zertifizierungssiegel_HR-Bewerbendenmangement_DE.png'
import Hrm from '../images/certificat/25_Abacus-Zertifizierungssiegel_HR-Mitarbeitendenmangement_DE.png'
import Spm from '../images/certificat/25_Abacus-Zertifizierungssiegel_Spesenmanagement_DE.png'

export const certificat = [
    {
       img: 'GoldPartner.png',
       link: Gold25,
    },
    {
        img: '25_Abacus-Zertifizierungssiegel_AbaNet_DE.png',
        link: Abanet,
     },
     {
        img: '25_Abacus-Zertifizierungssiegel_AbaTime_DE.png',
        link: Abatime,
     },
     {
        img: '25_Abacus-Zertifizierungssiegel_Spesenmanagement_DE.png',
        link: Spm,
     },
     {
        img: '25_Abacus-Zertifizierungssiegel_HR-Mitarbeitendenmangement_DE.png',
        link: Hrm,
     },
     {
        img: '25_Abacus-Zertifizierungssiegel_HR-Bewerbendenmangement_DE.png',
        link: Hrb,
     }
]


